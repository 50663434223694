import axios from "axios";
import React, { useState } from "react";
import { FaUser, FaPhone, FaPlus, FaCar, FaPaperPlane } from "react-icons/fa";
import {
  MdFamilyRestroom,
  MdOutlineChildFriendly,
  MdPerson2,
} from "react-icons/md";
import API from "../api";

const statesAndUT = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

function VehicleDetailsForm({
  tempId,
  termsAccepted,
  openModal,
  handleCheckboxChange,
}) {
  const [ownerDetails, setOwnerDetails] = useState({
    ownerName: "",
    vehicleNumber: "",
    registrationState: "Haryana",
    RCNo: "",
    idType: "AADHAR",
    idNo: "NA",
    driverName: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    ownerName: "",
    vehicleNumber: "",
  });

  const [family, setFamily] = useState([]);
  const [friends, setFriends] = useState([]);
  const [others, setOthers] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOwnerChange = (e) => {
    const { name, value } = e.target;
    setOwnerDetails((prev) => ({ ...prev, [name]: value }));
  };
  const handleCapitalChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase(); // Convert the value to uppercase

    setOwnerDetails((prev) => ({ ...prev, [name]: uppercaseValue }));
  };
  const handleFamilyChange = (index, e) => {
    const newFamily = [...family];
    const value = e.target.value;
    const name = e.target.name;
    if (name === "phoneNumber") {
      // Accept only numbers
      if (/^\d*$/.test(value)) {
        newFamily[index][name] = value;
      }
    } else {
      newFamily[index][name] = value;
    }
    setFamily(newFamily);
  };

  const handleFriendsChange = (index, e) => {
    const newFriends = [...friends];
    const value = e.target.value;
    const name = e.target.name;
    if (name === "phoneNumber") {
      // Accept only numbers
      if (/^\d*$/.test(value)) {
        newFriends[index][name] = value;
      }
    } else {
      newFriends[index][name] = value;
    }
    setFriends(newFriends);
  };

  const handleOthersChange = (index, e) => {
    const newOthers = [...others];
    const value = e.target.value;
    const name = e.target.name;
    if (name === "phoneNumber") {
      // Accept only numbers
      if (/^\d*$/.test(value)) {
        newOthers[index][name] = value;
      }
    } else {
      newOthers[index][name] = value;
    }
    setOthers(newOthers);
  };

  const addFamily = () => {
    if (family.length === 1) return;
    setFamily([...family, { name: "", phoneNumber: "", relation: "" }]);
  };

  const addFriends = () => {
    if (friends.length === 1) return;

    setFriends([...friends, { name: "", phoneNumber: "" }]);
  };

  const addOthers = () => {
    if (others.length === 1) return;

    setOthers([...others, { name: "", phoneNumber: "" }]);
  };
  const validateInput = () => {
    let isValid = true;
    let errors = {};
    if (!ownerDetails.ownerName) {
      errors.ownerName = "Owner name is required.";
      isValid = false;
    }
    if (!ownerDetails.vehicleNumber) {
      errors.vehicleNumber = "Vehicle number is required.";
      isValid = false;
    }

    setErrorMessages(errors);
    return isValid;
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault(); // Prevent default form submission behavior
    const payload = {
      ...ownerDetails,
      family,
      friends,
      others,
      tempId,
    };
    if (validateInput()) {
      try {
        const response = await API.post("/api/QR/submit-vehicle-form", payload);
        alert("Data submitted successfully");
        console.log(response.data);
        // console.log("In try");
      } catch (error) {
        console.error("Failed to submit data", error);
        alert("Failed to submit data");
      }
    } else {
      alert("Data Not Proper, Please Check");
    }
    setLoading(false);
  };

  return (
    <div className="mt-6 bg-[#00133e] min-h-screen text-white">
      <h1 className="text-3xl font-bold flex items-center mb-6">
        <FaCar className="mr-2" /> Vehicle Owner Details Form
      </h1>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <label className="flex items-center mb-2">
            <FaUser className="mr-2" /> Owner Name:
          </label>
          <input
            type="text"
            name="ownerName"
            value={ownerDetails.ownerName}
            onChange={handleOwnerChange}
            placeholder="Owner Name"
            className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
          />
          {errorMessages.ownerName && (
            <p className="text-red-500">{errorMessages.ownerName}</p>
          )}
        </div>
        <div>
          <label className="flex items-center mb-2">
            <FaUser className="mr-2" /> Driver Name:
          </label>
          <input
            type="text"
            name="driverName"
            value={ownerDetails.driverName}
            onChange={handleOwnerChange}
            placeholder="Owner Name"
            className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
          />
        </div>
        <div>
          <label className="flex items-center mb-2">
            <FaCar className="mr-2" /> Vehicle Number:
          </label>
          <input
            type="text"
            name="vehicleNumber"
            value={ownerDetails.vehicleNumber}
            onChange={handleCapitalChange}
            placeholder="Vehicle Number"
            className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
          />
          {errorMessages.ownerName && (
            <p className="text-red-500">{errorMessages.vehicleNumber}</p>
          )}
        </div>
        <div>
          <label className="flex items-center mb-2">
            <FaCar className="mr-2" /> Registration State:
          </label>
          <select
            name="registrationState"
            value={ownerDetails.registrationState}
            onChange={handleOwnerChange}
            className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
          >
            {statesAndUT.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="flex items-center mb-2">
            <FaCar className="mr-2" /> RC Number:
          </label>
          <input
            type="text"
            name="RCNo"
            value={ownerDetails.RCNo}
            onChange={handleCapitalChange}
            placeholder="RC Number"
            className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
          />
        </div>
        <div>
          <label className="flex items-center mb-2">
            <FaCar className="mr-2" /> ID Type:
          </label>
          <select
            name="idType"
            value={ownerDetails.idType}
            onChange={handleOwnerChange}
            className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
          >
            <option value="AADHAR">AADHAR</option>
            <option value="PAN_CARD">PAN CARD</option>
            <option value="VOTER_ID">VOTER ID</option>
            <option value="FAMILY_ID">FAMILY ID</option>
            <option value="OTHER">OTHER</option>
            <option value="NA">NA</option>
          </select>
        </div>
        <div>
          <label className="flex items-center mb-2">
            <FaCar className="mr-2" /> ID Number:
          </label>
          <input
            type="text"
            name="idNo"
            value={ownerDetails.idNo}
            onChange={handleCapitalChange}
            placeholder="ID Number"
            className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
          />
        </div>

        <button
          type="button"
          onClick={addFamily}
          className="mt-4 mb-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-700 flex items-center"
        >
          <FaPlus className="mr-2" />
          Add Family Member
        </button>
        {family.map((member, index) => (
          <div key={index} className="mb-4 p-4 bg-[#00276c] rounded">
            <h4 className="flex items-center mb-2">
              <MdFamilyRestroom className="mr-2" />
              Family Member #{index + 1}
            </h4>
            <input
              type="text"
              name="name"
              value={member.name}
              onChange={(e) => handleFamilyChange(index, e)}
              placeholder="Family Member Name"
              className="block w-full p-2 mb-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
            />
            <input
              type="text"
              name="phoneNumber"
              value={member.phoneNumber}
              onChange={(e) => handleFamilyChange(index, e)}
              placeholder="Phone Number"
              className="block w-full p-2 mb-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
            />
            <input
              type="text"
              name="relation"
              value={member.relation}
              onChange={(e) => handleFamilyChange(index, e)}
              placeholder="Relation"
              className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
            />
          </div>
        ))}

        <button
          type="button"
          onClick={addFriends}
          className="mt-4 mb-2 p-2 bg-green-500 text-white rounded hover:bg-green-700 flex items-center"
        >
          <FaPlus className="mr-2" />
          Add Friend
        </button>
        {friends.map((friend, index) => (
          <div key={index} className="mb-4 p-4 bg-[#00276c] rounded">
            <h4 className="flex items-center mb-2">
              <MdOutlineChildFriendly className="mr-2" />
              Friend #{index + 1}
            </h4>
            <input
              type="text"
              name="name"
              value={friend.name}
              onChange={(e) => handleFriendsChange(index, e)}
              placeholder="Friend's Name"
              className="block w-full p-2 mb-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
            />
            <input
              type="text"
              name="phoneNumber"
              value={friend.phoneNumber}
              onChange={(e) => handleFriendsChange(index, e)}
              placeholder="Phone Number"
              className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
            />
          </div>
        ))}

        <button
          type="button"
          onClick={addOthers}
          className="mt-4 mb-2 p-2 bg-purple-500 text-white rounded hover:bg-purple-700 flex items-center"
        >
          <FaPlus className="mr-2" />
          Add Other Contact
        </button>
        {others.map((other, index) => (
          <div key={index} className="mb-4 p-4 bg-[#00276c] rounded">
            <h4 className="flex items-center mb-2">
              <MdPerson2 className="mr-2" />
              Other #{index + 1}
            </h4>
            <input
              type="text"
              name="name"
              value={other.name}
              onChange={(e) => handleOthersChange(index, e)}
              placeholder="Other's Name"
              className="block w-full p-2 mb-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
            />
            <input
              type="text"
              name="phoneNumber"
              value={other.phoneNumber}
              onChange={(e) => handleOthersChange(index, e)}
              placeholder="Phone Number"
              className="block w-full p-2 border border-gray-300 rounded bg-[#0e1d4a] text-white"
            />
          </div>
        ))}
        <div className="mt-4">
          <input
            type="checkbox"
            id="terms"
            checked={termsAccepted}
            onChange={handleCheckboxChange}
            className="mr-2"
          />
          <label htmlFor="terms">Accept Terms and Conditions</label>
          <p onClick={openModal} className="ml-20 text-sm  text-white rounded ">
            Show Terms and Conditions
          </p>
        </div>
        <button
          type="submit"
          className={`mt-4 p-2  text-white rounded hover:bg-blue-700 flex items-center ${
            loading ? "bg-gray-200" : "bg-blue-500"
          }`}
        >
          <FaPaperPlane className="mr-2" /> {loading ? "Submit" : "Loading"}
        </button>
      </form>
    </div>
  );
}

export default VehicleDetailsForm;
