import React, { useEffect, useState } from "react";
import { BiLeftArrow } from "react-icons/bi";
import { BsBack } from "react-icons/bs";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FaLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

const SavedItems = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [savedItems, setSavedItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedItems = JSON.parse(sessionStorage.getItem("savedItems")) || [];
    setSavedItems(storedItems);
  }, []);
  const handleOrder = () => {
    alert("Order placed successfully!");
    navigate("/");
  };
  const addToCart = (item) => {
    setSavedItems((prevItems) => {
      const existingItem = prevItems.find(
        (savedItem) => savedItem.id === item.id
      );
      let updatedItems;
      if (existingItem) {
        updatedItems = prevItems.map((savedItem) =>
          savedItem.id === item.id
            ? { ...savedItem, quantity: savedItem.quantity + 1 }
            : savedItem
        );
      } else {
        updatedItems = [...prevItems, { ...item, quantity: 1 }];
      }
      sessionStorage.setItem("savedItems", JSON.stringify(updatedItems));
      return updatedItems;
    });
  };

  const removeFromCart = (item) => {
    setSavedItems((prevItems) => {
      const existingItem = prevItems.find(
        (savedItem) => savedItem.id === item.id
      );
      let updatedItems;
      if (existingItem.quantity > 1) {
        updatedItems = prevItems.map((savedItem) =>
          savedItem.id === item.id
            ? { ...savedItem, quantity: savedItem.quantity - 1 }
            : savedItem
        );
      } else {
        updatedItems = prevItems.filter(
          (savedItem) => savedItem.id !== item.id
        );
      }
      sessionStorage.setItem("savedItems", JSON.stringify(updatedItems));
      return updatedItems;
    });
  };

  let tableNumber = 10;
  const handleSendMessage = () => {
    let message = `🍽️ *Order for Table ${tableNumber}* 🎉:\n\n`;
    savedItems.forEach((item, index) => {
      message +=
        `📌 *${index + 1}. ${item.name}* - ₹${item.price}\n` +
        `🔢 Quantity: ${item.quantity}\n\n`;
    });
    message += `💸 *Total:* ₹${savedItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    )}\n\n`;
    message += `👉 Please confirm by replying to this message. 📩\n\n`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/918448804428?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Saved Items</h1>
      {savedItems.length > 0 ? (
        <div>
          {savedItems.map((item, index) => {
            let cartItem = savedItems.find(
              (savedItem) => savedItem.id === item.id
            );
            return (
              <div
                key={item.id}
                className="bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow"
              >
                <div className="flex items-center gap-4">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-24 h-24 object-cover rounded"
                  />
                  <div className="flex-1">
                    <h3 className="text-lg font-bold text-gray-800">
                      {item.name}
                    </h3>
                    <p className="text-sm text-gray-600">{item.description}</p>
                    <div className="flex items-center justify-between mt-2">
                      <span className="text-md font-bold text-gray-800">
                        ₹{item.price}
                      </span>
                      {cartItem && (
                        <div className="flex gap-3 items-center">
                          {cartItem && (
                            <button
                              onClick={() => removeFromCart(item)}
                              className="px-3 py-2 bg-red-500 text-white rounded"
                            >
                              <FaMinus />
                            </button>
                          )}
                          <span className="font-bold">
                            {cartItem ? cartItem.quantity : 0}
                          </span>
                          <button
                            onClick={() => addToCart(item)}
                            className="px-3 py-2 bg-blue-500 text-white rounded"
                          >
                            <FaPlus />
                          </button>
                        </div>
                      )}
                      {!cartItem && (
                        <button
                          onClick={() => addToCart(item)}
                          className={`px-3 py-1 text-sm rounded ${
                            item.available
                              ? "bg-blue-500 text-white"
                              : "bg-gray-300 text-gray-500"
                          }`}
                          disabled={!item.available}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <h2 className="text-2xl font-bold mt-6 text-gray-800 text-center">
            Total: ₹
            {savedItems.reduce(
              (acc, item) => acc + item.price * item.quantity,
              0
            )}
          </h2>
          <button
            onClick={handleSendMessage}
            className="mt-4 w-full bg-green-500 text-white font-bold py-2 px-4 rounded-full shadow-md hover:bg-green-600 transition"
          >
            Order Now
          </button>
        </div>
      ) : (
        <p className="text-gray-600 text-center">No items saved.</p>
      )}
      <div
        onClick={() => navigate(-1)}
        className="fixed w-full left-0 bottom-0 bg-blue-600 text-white px-4 py-2  shadow-lg h-20 text-xl flex items-center gap-2"
      >
        <span>
          <FaLeftLong />
        </span>
        Back to Menu
      </div>
    </div>
  );
};

export default SavedItems;
