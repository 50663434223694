import React, { useState, useRef, useEffect } from "react";
import "./MenuCard.css";
import { Link, useNavigate } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import { BiMenu } from "react-icons/bi";
import { CiMenuBurger } from "react-icons/ci";
import { GiPlateClaw } from "react-icons/gi";
import { IoRestaurant } from "react-icons/io5";
import { MdRestaurant } from "react-icons/md";

const MenuCard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRefs = useRef({});
  const [savedItems, setSavedItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("savedItems")) || [];
    setSavedItems(storedItems);
  }, []);
  const menu = [
    {
      category: "Street Food",
      items: [
        {
          id: 1,
          name: "Amritsari Puri Subzi with Halwa (Desi Ghee)",
          price: 80,
          description: "A classic Punjabi breakfast dish served with halwa.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 2,
          name: "Channa Bhatura (Desi Ghee)",
          price: 120,
          description: "Channa served with fried bhatura in desi ghee.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 3,
          name: "Matar Kulcha",
          price: 85,
          description: "Kulcha served with spicy green peas curry.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 4,
          name: "Choley Kulcha",
          price: 95,
          description: "Soft kulchas served with choley.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 5,
          name: "Pao Bhaji (12:30 pm to 10:30 pm)",
          price: 120,
          description: "Mashed vegetable curry served with soft pav.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 6,
          name: "Extra Pao",
          price: 20,
          description: "Additional pav for your bhaji.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 7,
          name: "Extra Bhaji + Salad",
          price: 55,
          description: "Extra bhaji with a side of fresh salad.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 8,
          name: "Samosa with Chutney / Choley",
          price: 34,
          description: "Fried samosas served with chutney or choley.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 9,
          name: "Pithi Kachori with Chutney / Choley",
          price: 34,
          description: "Pithi kachoris served with chutney or choley.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 10,
          name: "Onion Kachori with Chutney / Choley",
          price: 60,
          description: "Onion-filled kachori served with chutney or choley.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 11,
          name: "Paneer Pakora with Chutney / Choley",
          price: 52,
          description: "Crispy paneer pakoras served with chutney or choley.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 12,
          name: "Plain Dhokla",
          price: 240,
          description: "Soft and fluffy plain dhokla.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 13,
          name: "Fried Dhokla",
          price: 240,
          description: "Crispy fried dhokla served with chutney.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 14,
          name: "Bhel Puri (New)",
          price: 70,
          description: "Puffed rice mixed with vegetables, chutney, and sev.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 15,
          name: "Raj Kachori",
          price: 100,
          description: "Crispy kachori filled with spicy yogurt and chutney.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 16,
          name: "Papdi Chaat",
          price: 100,
          description: "Crispy papdi topped with yogurt, chutney, and spices.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 17,
          name: "Dahi Bhalla",
          price: 100,
          description: "Soft dahi bhallas soaked in yogurt and chutney.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 18,
          name: "Gol Gappe (5 pcs, New)",
          price: 45,
          description: "Puffed balls filled with spicy water and potatoes.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 19,
          name: "Gol Gappa Chaat (New)",
          price: 90,
          description: "Gol gappas served with chutney and spicy potatoes.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
      ],
    },
    {
      category: "South Indian",
      items: [
        {
          id: 20,
          name: "Vada Sambhar",
          price: 110,
          description: "Fried vadas served with sambhar and chutney.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 21,
          name: "Idli Sambhar",
          price: 110,
          description: "Soft steamed idlis served with sambhar and chutney.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 22,
          name: "Plain Dosa",
          price: 150,
          description: "Thin, crispy dosa served with chutney and sambhar.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 23,
          name: "Masala Dosa",
          price: 175,
          description: "Crispy dosa stuffed with spiced potatoes.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 24,
          name: "Onion Masala Dosa",
          price: 190,
          description: "Onion-filled dosa with spicy potatoes.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 25,
          name: "Paneer Dosa",
          price: 200,
          description: "Crispy dosa stuffed with spiced paneer.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 26,
          name: "Uttapam (Onion / Mix Veg)",
          price: 180,
          description: "Thick pancake served with chutney and sambhar.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 27,
          name: "Rawa Plain Dosa",
          price: 180,
          description: "Crispy rawa dosa served with chutney and sambhar.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 28,
          name: "Rawa Onion Dosa",
          price: 190,
          description: "Rawa dosa with onions and spices.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 29,
          name: "Rawa Masala Dosa",
          price: 200,
          description: "Rawa dosa stuffed with spicy potatoes.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 30,
          name: "Rawa Onion Masala Dosa",
          price: 210,
          description: "Rawa dosa with onions and spicy potatoes.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 31,
          name: "South Indian Platter",
          price: 280,
          description: "A platter featuring popular South Indian dishes.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
      ],
    },
    {
      category: "China Express",
      items: [
        {
          id: 32,
          name: "Veg Momos (Steamed)",
          price: 70,
          description: "Steamed vegetarian momos served with chutney.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 33,
          name: "Crispy Chilli Potato",
          price: 150,
          description: "Crispy potatoes tossed in a spicy chilli sauce.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 34,
          name: "Veg Spring Roll",
          price: 150,
          description: "Crispy rolls filled with seasoned vegetables.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 35,
          name: "Veg Chow Mein",
          price: 150,
          description: "Stir-fried noodles with vegetables and sauces.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 36,
          name: "Garlic Chow Mein",
          price: 160,
          description: "Noodles tossed with garlic and vegetables.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 37,
          name: "Hakka Noodles",
          price: 160,
          description: "Traditional Hakka-style noodles with vegetables.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 38,
          name: "Veg Fried Rice",
          price: 170,
          description: "Fried rice cooked with mixed vegetables.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 39,
          name: "Chilli Paneer (Dry/Gravy)",
          price: 200,
          description: "Paneer tossed in a spicy chilli sauce.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 40,
          name: "Veg Manchurian (Dry/Gravy)",
          price: 200,
          description: "Vegetable dumplings in a tangy Manchurian sauce.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
        {
          id: 41,
          name: "Chinese Platter",
          price: 270,
          description: "A platter of popular Chinese dishes.",
          image:
            "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
          available: true,
          type: "Veg",
        },
      ],
    },
    // Continue for "Soup & Salad", "The Rice Bowl", "A' La Carte", and "Beverages"
  ];

  const categories = menu.map((cat) => {
    return { name: cat.category, refKey: cat.category };
  });

  const scrollToCategory = (category) => {
    menuRefs.current[category].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setIsMenuOpen(false); // Close the modal after navigating
  };
  const items = [
    {
      id: 39,
      name: "Chilli Paneer (Dry/Gravy)",
      price: 200,
      description: "Paneer tossed in a spicy chilli sauce.",
      image:
        "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
      available: true,
      type: "Veg",
      quantity: 2,
    },
    {
      id: 40,
      name: "Veg Manchurian (Dry/Gravy)",
      price: 200,
      description: "Vegetable dumplings in a tangy Manchurian sauce.",
      image:
        "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
      available: true,
      type: "Veg",
      quantity: 3,
    },
    {
      id: 41,
      name: "Chinese Platter",
      price: 270,
      description: "A platter of popular Chinese dishes.",
      image:
        "https://img.buzzfeed.com/buzzfeed-static/static/2014-06/23/15/campaign_images/webdr07/26-traditional-indian-foods-that-will-change-your-1-7312-1403550756-15_big.jpg?resize=1200:*",
      available: true,
      type: "Veg",
      quantity: 5,
    },
  ];
  const upiLink = "upi://pay?pa=7404121102@ybl&pn=Bansal%20Sweets&cu=INR"; // Customized UPI link
  let tableNumber = 10;
  const handleSendMessage = () => {
    let message = `🍽️ *Order for Table ${tableNumber}* 🎉:\n\n`;
    items.forEach((item, index) => {
      message +=
        `📌 *${index + 1}. ${item.name}* - ₹${item.price}\n` +
        `🔢 Quantity: ${item.quantity}\n\n`;
    });
    message += `💸 *Total:* ₹${items.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    )}\n\n`;
    message += `👉 Please confirm by replying to this message. 📩\n\n`;
    message += `💳 *Pay Now:* [Click here to pay](${upiLink}) 🚀`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/918448804428?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };
  const addToCart = (item) => {
    setSavedItems((prevItems) => {
      const existingItem = prevItems.find(
        (savedItem) => savedItem.id === item.id
      );
      let updatedItems;
      if (existingItem) {
        updatedItems = prevItems.map((savedItem) =>
          savedItem.id === item.id
            ? { ...savedItem, quantity: savedItem.quantity + 1 }
            : savedItem
        );
      } else {
        updatedItems = [...prevItems, { ...item, quantity: 1 }];
      }
      sessionStorage.setItem("savedItems", JSON.stringify(updatedItems));
      return updatedItems;
    });
  };

  const removeFromCart = (item) => {
    setSavedItems((prevItems) => {
      const existingItem = prevItems.find(
        (savedItem) => savedItem.id === item.id
      );
      let updatedItems;
      if (existingItem.quantity > 1) {
        updatedItems = prevItems.map((savedItem) =>
          savedItem.id === item.id
            ? { ...savedItem, quantity: savedItem.quantity - 1 }
            : savedItem
        );
      } else {
        updatedItems = prevItems.filter(
          (savedItem) => savedItem.id !== item.id
        );
      }
      sessionStorage.setItem("savedItems", JSON.stringify(updatedItems));
      return updatedItems;
    });
  };
  console.log(savedItems);
  return (
    <div className="p-4 bg-gray-100 min-h-screen relative">
      {/* Restaurant Header */}
      <div className="bg-white rounded-lg shadow-lg p-2 mb-6">
        <h1 className="text-4xl font-bold  text-center text-blue-900">
          Bansal Sweets
        </h1>
        <h1 className="text-xl text-center text-green-600 mb-4">Palwal</h1>
        <img
          src={require("../../assets/images/menu/enjoy_the_meel.png")}
          alt="Enjoy the Meal"
          className="w-36 mx-auto mb-6"
        />

        <div className="flex justify-between items-center">
          <button
            className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white transition-colors duration-150 rounded-full shadow-lg p-3 cursor-pointer"
            onClick={() =>
              window.open("https://maps.app.goo.gl/bb26KSb3MatzH58P9", "_blank")
            }
          >
            <img
              src="https://logos-download.com/wp-content/uploads/2016/05/Google_Maps_logo_icon.png"
              alt="Google Maps Icon"
              className="w-6 h-6"
            />
            <span className="text-sm font-medium">Share Location</span>
          </button>

          <button
            className="flex items-center gap-2 bg-green-500 hover:bg-green-600 text-white transition-colors duration-150 rounded-full shadow-lg p-3 cursor-pointer"
            onClick={() =>
              window.open("https://maps.app.goo.gl/bb26KSb3MatzH56P9", "_blank")
            }
          >
            <img
              src="https://logodix.com/logo/1645140.png"
              alt="UPI Payment Icon"
              className="w-12 h-6"
            />
            <span className="text-sm font-medium">Pay the bill</span>
          </button>
        </div>
      </div>

      {/* Menu */}
      {menu.map((section, index) => (
        <div
          key={index}
          className="mb-8"
          ref={(el) => (menuRefs.current[section.category] = el)}
        >
          <h2 className="text-lg font-bold text-gray-800 mb-4">
            {section.category}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {section.items.map((item) => {
              let cartItem = savedItems.find(
                (savedItem) => savedItem.id === item.id
              );

              return (
                <div
                  key={item.id}
                  className="bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow"
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-24 h-24 object-cover rounded"
                    />
                    <div className="flex-1">
                      <h3 className="text-lg font-bold text-gray-800">
                        {item.name}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {item.description}
                      </p>
                      <div className="flex items-center justify-between mt-2">
                        <span className="text-md font-bold text-gray-800">
                          ₹{item.price}
                        </span>
                        {cartItem && (
                          <div className="flex gap-3 items-center">
                            {cartItem && (
                              <button
                                onClick={() => removeFromCart(item)}
                                className="px-3 py-2 bg-red-500 text-white rounded"
                              >
                                <FaMinus />
                              </button>
                            )}
                            <span className="font-bold">
                              {cartItem ? cartItem.quantity : 0}
                            </span>{" "}
                            <button
                              onClick={() => addToCart(item)}
                              className="px-3 py-2 bg-blue-500 text-white rounded"
                            >
                              <FaPlus />
                            </button>
                          </div>
                        )}
                        {!cartItem && (
                          <button
                            onClick={() => addToCart(item)}
                            className={`px-3 py-1  rounded ${
                              item.available
                                ? "bg-blue-500 text-white"
                                : "bg-gray-300 text-gray-500"
                            }`}
                            disabled={!item.available}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}

      {/* Floating Menu Button */}
      <div className="fixed bottom-12 right-8 z-50">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="bg-gray-800 text-white w-18 h-12 rounded-md px-6 shadow-lg flex items-center justify-center  gap-2"
          style={isMenuOpen ? { background: "green" } : {}}
        >
          <span>
            <MdRestaurant />
          </span>
          Menu
        </button>

        {/* Category Modal */}
        {isMenuOpen && (
          <div className="absolute bottom-16 right-0 bg-gray-800 text-white rounded-lg shadow-lg w-48 p-2">
            {categories.map((category, index) => (
              <button
                key={index}
                className="block w-full text-left px-3 py-2 bg-gray-800 text-white hover:bg-gray-100 rounded"
                onClick={() => scrollToCategory(category.refKey)}
              >
                {category.name}
              </button>
            ))}
          </div>
        )}
      </div>
      <div
        onClick={() => navigate("/menu/saved-items", { state: { savedItems } })}
        className="fixed w-full left-0 bottom-0 bg-blue-600 text-white px-4 py-2  shadow-lg h-16 text-xl"
      >
        🛒 Saved Items ({savedItems.length})
      </div>
    </div>
  );
};

export default MenuCard;
